<script setup lang="ts">
import {ref, reactive, useScreenWidthChecker} from "#imports";
import {Breakpoint} from "~/enums/layouts/Breakpoint";
import DropdownMenu from "~/components/layouts/dropdown-menu.client.vue";
import NotificationMenu from "~/components/layouts/notification-menu.client.vue";
import ProfileMenu from "~/components/layouts/profile-menu.client.vue";
import {DropdownMenuOptionsClass} from "~/interfaces/layouts/IDropdownMenuOptions";
import type {IDropdownMenuItem} from "~/interfaces/layouts/IDropdownMenuOptions";

const title = 'Nuxt Core Admin';
const isOpened = ref(true);
const dropdownList = reactive(new DropdownMenuOptionsClass());
const notifications = reactive({
	counter: 0,
	items: [],
});

const {screenSize} = useScreenWidthChecker();
watch(() => screenSize.isScreenUnderMd, (newValue, oldValue) => {
	isOpened.value = !newValue;
});

const toggleOpen = () => {
	if (window.innerWidth < Breakpoint.MD) {
		isOpened.value = !isOpened.value;
	}
};

</script>
<template>
	<ClientOnly>
		<div class="layout-header__container" :class="{'opened': isOpened}">
			<div class="layout-header__datas">
				<img src="assets/images/nuxt.png" alt="Logo"/>
				<p>{{ title }}</p>
				<i v-if="screenSize.isScreenUnderMd" class="fa-solid fa-bars menu-icon" @click="toggleOpen"></i>
			</div>
			<div class="layout-header__actions">
				<dropdown-menu
						v-if="dropdownList.items.filter((element:IDropdownMenuItem) => element.id !== 0).length > 0"
						:options="dropdownList"
						class="mt-3 my-md-auto me-0 me-md-3 me-lg-5"
				/>
				<div class="d-flex mb-3 my-lg-0">
					<notification-menu
							v-if="notifications.items.length > 0"
							:options="notifications"
							class="my-auto me-0 me-md-3 me-lg-5"
					/>

				</div>
				<profile-menu class="mt-2"/>
			</div>
		</div>
	</ClientOnly>
</template>

