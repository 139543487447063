<script setup lang="ts">
import {ref} from "#imports";
import PropType from "#imports";
import type {IDropdownMenuOptions} from "~/interfaces/layouts/IDropdownMenuOptions";
import {DropdownMenuOptionsClass} from "~/interfaces/layouts/IDropdownMenuOptions";

const isOpened = ref(false);
defineProps({
	options: {
		type: Object as PropType<IDropdownMenuOptions>,
		required: true,
		default: new DropdownMenuOptionsClass(),
	}
})
const toggleOpen = () => {
	isOpened.value = !isOpened.value;
}
</script>
<template>
	<div class="dropdown-menu__container" :class="{'opened': isOpened}">
		<div class="dropdown-menu__header" @click="toggleOpen">
			{{ options.title }}
			<i class="fa-solid fa-chevron-down ms-1"></i>
		</div>
		<ul class="dropdown-menu__list">
			<li class="dropdown-menu__list-item" v-for="item in options.items">{{ item.title }}</li>
		</ul>
	</div>
</template>
