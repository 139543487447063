<script setup lang="ts">
import LayoutHeader from "~/components/layouts/layout-header.client.vue";
import LayoutSidebarLeft from "~/components/layouts/layout-sidebar-left.client.vue";
import LayoutFooter from "~/components/layouts/layout-footer.client.vue";
import {useAuthState} from "#imports";

const {getMe, isLoaded} = useAuthState();
getMe();

</script>

<template>
	<ClientOnly>
		<div v-if="isLoaded" class="layout__container">
			<layout-header/>
			<div class="layout__content">
				<layout-sidebar-left/>
				<div class="layout__content-view">
					<slot/>
				</div>
			</div>
			<layout-footer/>
		</div>
	</ClientOnly>
</template>

<style scoped>

</style>
