<script setup lang="ts">
import {onMounted, ref, useNuxtApp, useScreenWidthChecker} from '#imports';
import {PanelType} from "~/enums/layouts/PanelType";

const selectedPanel = ref<string>(PanelType.NONE);
const {screenSize} = useScreenWidthChecker();
const {$globalBusEmitter} = useNuxtApp();

onMounted(() => {
	initLayoutSidebarEmitter();
});

const selectPanelHandler = (newSelectedPanel: PanelType): void => {
	if (screenSize.isScreenUnderLg) {
		if (selectedPanel.value === newSelectedPanel) {
			$globalBusEmitter.$emit('LayoutFooter-UpdateSelectedPanel', PanelType.NONE);
			selectedPanel.value = PanelType.NONE;
		} else {
			$globalBusEmitter.$emit('LayoutFooter-UpdateSelectedPanel', newSelectedPanel);
			selectedPanel.value = newSelectedPanel;
		}
	}
}
const initLayoutSidebarEmitter = (): void => {
	$globalBusEmitter.$on('LayoutSidebar-UpdateSelectedPanel', (panelType: any) => {
		selectedPanel.value = panelType;
	});
}
</script>
<template>
	<footer v-if="!screenSize.isScreenUnderLg" class="layout-footer__container">
		<div class="layout-footer__logo">
			Nuxt Core Admin | Kezelőfelület Alpha
		</div>
	</footer>

	<footer v-if="screenSize.isScreenUnderLg" class="layout-footer__container">
		<button
				type="button"
				class="layout-footer__btn"
				:class="{'active': selectedPanel === PanelType.MENU}"
				@click="selectPanelHandler(PanelType.MENU)"
		>
			<i class="fa-solid fa-ellipsis-vertical"></i>
			<span>Menü</span>
		</button>
		<button
				type="button"
				class="layout-footer__btn"
				:class="{'active': selectedPanel === PanelType.LIST}"
				@click="selectPanelHandler(PanelType.LIST)"
		>
			<i class="fa-solid fa-list-ul"></i>
			<span>Lista</span>
		</button>
		<button
				v-if="false"
				type="button"
				class="layout-footer__btn"
				:class="{'active': selectedPanel === PanelType.PANEL}"
				@click="selectPanelHandler(PanelType.PANEL)"
		>
			<i class="fa-solid fa-gear"></i>
			<span>Gyorspanel</span>
		</button>
	</footer>
</template>

