import {ref, useNuxtApp, useScreenWidthChecker, onMounted, watch} from "#imports";
import {Breakpoint} from "~/enums/layouts/Breakpoint";
import {PanelType} from "~/enums/layouts/PanelType";

export function useLayoutSidebar() {
	const isOpened = ref(true);
	const {screenSize} = useScreenWidthChecker();
	const {$globalBusEmitter} = useNuxtApp();

	onMounted(() => {
		isOpened.value = window.innerWidth > Breakpoint.XL;
	});

	watch(() => screenSize.isScreenUnderLg, (newValue, oldValue) => {
		isOpened.value = !newValue;
	});

	const toggleOpen = (panelType: PanelType): void => {
		isOpened.value = !isOpened.value;
		if (screenSize.isScreenUnderLg) {
			$globalBusEmitter.$emit('LayoutSidebar-UpdateSelectedPanel', isOpened.value ? panelType : PanelType.NONE);
		}
	}

	const initLayoutFooterEmitter = (sidebarPanelType: PanelType): void => {
		$globalBusEmitter.$on('LayoutFooter-UpdateSelectedPanel', (panelType: any) => {
			isOpened.value = panelType === sidebarPanelType;
		});
	}

	return {
		toggleOpen,
		initLayoutFooterEmitter,
		isOpened
	}
}
