export interface IDropdownMenuOptions {
    id: string,
    title: string,
    items: IDropdownMenuItem[],
}

export interface IDropdownMenuItem {
    id: number,
    title: string,
    link: string,
}


export class DropdownMenuOptionsClass implements IDropdownMenuOptions {
    id = '';
    title = '';
    items = [new DropdownMenuItemClass()];
}

export class DropdownMenuItemClass implements IDropdownMenuItem {
    id = 0;
    title = '';
    link = '';
}
