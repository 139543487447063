<script setup lang="ts">
import {ref} from "#imports";
import PropType from "#imports";
import type {INotificationMenuOptions} from "~/interfaces/layouts/INotificationMenuOptions";

const isOpened = ref(false);
defineProps({
	options: {
		type: Object as PropType<INotificationMenuOptions>,
		required: true,
		default: {},
	}
});
const toggleOpen = () => {
	isOpened.value = !isOpened.value;
}
</script>
<template>
	<div class="notification-menu__container" :class="{'opened': isOpened}">
		<div @click="toggleOpen">
			<button type="button" class="icon-item"><i class="fa-solid fa-bell"></i></button>
			<span class="notification-menu__counter">
                <template v-if="options.counter > 99">99+</template>
                <template v-else>{{ options.counter }}</template>
            </span>
		</div>
		<div class="notification-menu__list">
			<div class="notification-menu__list-header">
				Értesítések - <span>{{ options.counter }} db</span>
			</div>
			<div v-for="item in options.items" class="notification-menu__list-item">
				<i :class="item.icon"></i>
				<div>
					<strong>{{ item.title }}</strong>
					<p>{{ item.description.slice(0, 40) }}...</p>
				</div>
			</div>
			<div class="notification-menu__list-footer">
				Összes értesítés megtekintése
			</div>
		</div>
	</div>
</template>


