<script setup lang="ts">
import {ref, useScreenWidthChecker, useRouter, useSweetAlert, useAuthState, onMounted} from "#imports";

const {screenSize} = useScreenWidthChecker();
const {customSuccessAlert, customErrorAlert} = useSweetAlert();
const {$api} = useNuxtApp();
const {clearToken, currentUser} = useAuthState();
const router = useRouter();
const isOpened = ref(false);

const toggleOpen = () => {
	isOpened.value = !isOpened.value;
}

const logout = async () => {
	const {data: response, pending, error} = await $api.auth.getLogout();
	if (response.value) {
		customSuccessAlert('Sikeres kijelentkezés!', '');
		clearToken();
		await router.push({path: '/login'});
	}
	if (error.value) {
		customErrorAlert('Hiba', error.value?.message);
	}
}
</script>

<template>
	<div class="profile-menu__container" :class="{'opened': isOpened || screenSize.isScreenUnderMd}">
		<div class="profile-menu__header" @click="toggleOpen">
			<div class="user-logo">
				{{ currentUser?.name.slice(0, 1).toString().toUpperCase() }}
			</div>
			<span class="user-name">{{ currentUser?.name }}</span>
			<i class="fa-solid fa-bars menu-icon d-none d-md-block"></i>
		</div>
		<ul class="profile-menu__body">
			<li @click.prevent="logout">
				<i class="fa-solid fa-right-from-bracket me-1"></i> Kijelentkezés
			</li>
		</ul>
	</div>
</template>

