<script setup lang="ts">
import {navigateTo, ref, useRoute} from "#imports";
import type {PropType} from "vue";
import type {ILayoutSidebarMenu} from "~/interfaces/layouts/ILayoutSidebarMenu";

const route = useRoute()
const props = defineProps({
	options: {
		type: Object as PropType<ILayoutSidebarMenu>,
		required: true,
	}
})
const isOpened = ref<Boolean>(false);

const clickMenu = () => {
	if (props.options.url) {
		navigateTo(props.options.url)
	} else {
		isOpened.value = !isOpened.value;
	}
};
</script>
<template>
	<div class="details-unit__container" :class="{'opened': (isOpened || route.path === options.url)}">
		<div class="details-unit__header" @click="clickMenu">
			<div class="d-flex align-items-center">
				<i :class="options.icon" class="me-3"></i>
				<p class="details-unit__title">{{ options.title }}</p>
			</div>
			<i v-if="options.children?.length" class="fa-solid fa-chevron-down"></i>
		</div>

		<ul class="details-unit__content">
			<li v-for="item in options.children">
				<nuxt-link :to="{name: item.link}" class="details-unit__content-item">
					{{ item.title }}
				</nuxt-link>
			</li>
		</ul>
	</div>
</template>
