<script setup lang="ts">
import {ref, onMounted, useAuthState} from "#imports";
import {LayoutSidebarContainerPosition} from "~/enums/layouts/LayoutSidebarContainerPosition";
import type {ILayoutSidebarActionBtn} from "~/interfaces/layouts/ILayoutSidebarActionBtn";
import {PanelType} from "~/enums/layouts/PanelType";
import {useLayoutSidebar} from "~/composables/use-layout-sidebar";
import DetailsUnit from "~/components/units/details-unit.vue";
import LayoutSidebarContainer from "~/components/layouts/layout-sidebar-container.client.vue";
import SidebarTooltip from "~/components/layouts/sidebar-tooltip.client.vue";
import type {ILayoutSidebarMenu} from "~/interfaces/layouts/ILayoutSidebarMenu";
import {AdminType} from "~/enums/admin/AdminType";

const containerPosition = LayoutSidebarContainerPosition;
const panelType = PanelType;
const actions = ref<ILayoutSidebarActionBtn[]>([]);

const {toggleOpen, initLayoutFooterEmitter, isOpened} = useLayoutSidebar();
const {currentUser} = useAuthState();

onMounted(() => {
	initActions();
	initLayoutFooterEmitter(PanelType.MENU);
});

const initActions = (): void => {
	actions.value = [];
};
const initContent = (): ILayoutSidebarMenu[] => {
	return [
		{
			id: 0,
			title: 'Dashboard',
			icon: 'fa-solid fa-house',
			url: '/',
			children: [],
		},
		{
			id: 1,
			title: 'Adminisztrátorok',
			icon: 'fa-solid fa-user-group',
			permissionId: 9999,
			url: '/admins',
			role: 10000,
		},
		// {
		// 	id: 3,
		// 	title: 'Adminisztrátorok',
		// 	icon: 'fa-solid fa-user-group',
		// 	permissionId: 9999,
		// 	role: 10000,
		// 	children: [
		// 		{
		// 			id: 1,
		// 			title: 'Adminisztrátorok',
		// 			url: '/asd'
		// 		}
		// 	],
		// },
	];
}

</script>
<template>
	<layout-sidebar-container
			:position="containerPosition.LEFT"
			:class="{'opened': isOpened}"
			@toggleOpen="toggleOpen(panelType.MENU)"
	>
		<template #content>
			<template v-for="item in initContent()">
				<!--				<template v-if="currentUser?.role_id === AdminType.DEVELOPER || currentUser?.role_id === AdminType.CUSTOMER && item?.role === undefined || currentUser?.role_id === AdminType.ADMIN && currentUser?.permissions?.some(permission => Number(permission) === item.permissionId)">-->
				<details-unit v-if="isOpened" :options="item"/>
				<sidebar-tooltip v-else :options="item"/>
				<!--				</template>-->
			</template>
		</template>
	</layout-sidebar-container>
</template>

