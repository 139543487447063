<script setup lang="ts">
import {ILayoutSidebarContainerPosition} from "~/interfaces/layouts/ILayoutSidebarContainerPosition";

const emits = defineEmits(['toggleOpen']);
defineProps({
	position: {
		type: String,
		required: true,
	},
})

const containerPosition = ILayoutSidebarContainerPosition;
const toggleOpen = () => {
	emits('toggleOpen');
}

</script>
<template>
	<div class="layout-sidebar__container" :class="position">
		<div class="layout-sidebar__header">
			<div>
				<slot name="header"></slot>
			</div>
			<button type="button" class="layout-sidebar__header-toggle-btn" @click="toggleOpen">
				<i v-if="position === containerPosition.LEFT" class="fa-solid fa-chevron-left"></i>
				<i v-if="position === containerPosition.RIGHT" class="fa-solid fa-chevron-right"></i>
			</button>
		</div>
		<div class="layout-sidebar__actions">
			<slot name="actions"></slot>
		</div>
		<div class="layout-sidebar__content">
			<slot name="content"></slot>
		</div>
	</div>
</template>
